import React,{ useRef,useState } from 'react';
import * as Mui  from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function GetStatus(props) {
  
  const statusCustomer = (_status,_statusInfo,_response=0) => {
    switch(_status) { 
      case 0: {
         //Open
         return( 
          <Mui.Chip sx={{minWidth:'90px'}}
            label={_statusInfo} 
            color="default"
          /> 
          )
         break;
         
      } 
      case 1: {
        //Close
        return( 
          <Mui.Chip sx={{minWidth:'90px', backgroundColor:'#222222', color:'#ffffff'}}
            label={_statusInfo}
          /> 
          )
         break; 
      } 
      case 2: { 
        //Suspended
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="success"
          />
          ) 
         break; 
      } 
      case 3: { 
        //Terminated
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="error"
          />
          ) 
         break; 
      } 
      case 4: { 
        //Reject
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="error"
          />
          ) 
         break; 
      } 
      case 5: { 
        //Order Suspend
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
            label={_statusInfo}  
            color="secondary"
          />
          ) 
         break; 
      } 
      case 6: { 
        //Order Unsuspend
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="primary"
          />
          ) 
         break; 
      } 
      default: { 
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="default"
          />
          ) 
         break; 
      } 
   } 
   
  }
  
  return statusCustomer(props.status, props.statusInfo,props.response);
}
