import React,  { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';

import { MuiFileInput } from 'mui-file-input';
import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';
import MsgBox from '../lib/MsgBox';


const DrawerAllocateVendor = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [row,setRow] = React.useState(2)
    const [col,setCol] = React.useState(2)
    const [vendor,setVendor] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, getValues, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const formdata = new URLSearchParams(new FormData());
      const vendor_id = getValues('vendor').split("|")[1].trim();

      formdata.append("product_id", state.product_id)
      formdata.append("vendor_id", vendor_id)
      formdata.append("qty", getValues("qty"))

      console.log(Object.fromEntries(formdata))


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      fetch(props.config.hostApi + 'vendor-allocation/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            if(result.success){
              refreshVendorAllocation(state.product_id)
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Vendor Allocation',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            console.log('disini')
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm: false,
      product_id: ''
    });

    useImperativeHandle(ref, () => ({
      openForm: (product_id) => {
        setState({ 
          isForm: true, 
          product_id: product_id
         })
      }
   }));

    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    
    const getVendor = () => {
      setLoading(true)
      const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 
       'vendor',
       {
        search: '',
        perPage: '1000',
        page:1,
       },
       { headers: header })
        .then(res => {
            var vendorList=[];
            for(var i = 0; i < res.data.data.length; i++){
                vendorList.push({label:`${res.data.data[i].name} (${res.data.data[i].phone} ) | ${res.data.data[i].id}`})
            }
            setVendor(vendorList)
            setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }

    const refreshVendorAllocation = (product_id) => {
      props.refreshVendorAllocation(state.product_id)
      setState({
        isForm: false,
        product_id: ''
      })
    }


  React.useEffect(() => {
    getVendor()

  },[]); 

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Submit Vendor Allocation
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <Mui.Button
                    variant="contained"
                    disabled={state.loading}
                    onClick={()=> setState({isForm:false,
                      bng: [],
                      vendor:[],
                      vendor_product_type:[],
                      snmp_version:[],
                      remote_protocol:[]})}    
                    sx={{ margin: '0px 3px' }}
                  >
                      <MuiIcon.Replay/> Back 
                  </Mui.Button>
                  <MuiLab.LoadingButton
                    type="submit"
                    loading={state.loading}
                    disabled={state.loading}
                    loadingPosition="start"
                    variant="contained"  
                    sx={{ margin: '0px 3px'}}
                    startIcon={<MuiIcon.Save />} 
                    > Submit
                  </MuiLab.LoadingButton>
            </Mui.Toolbar>
            <Mui.DialogContent>
                    <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                            <span>* Require Field</span>
                        </Mui.Grid>
                          <Mui.Grid item xs={9} className="innerForm">
                                <Mui.Autocomplete
                                  disablePortal
                                  options={vendor}
                                  renderInput={(params) => (
                                    <Mui.TextField {...params} size="small" label="Vendor" {...register("vendor")} />
                                  )}
                                  onChange={()=>{
                                    console.log(getValues('vendor'))
                                  }}
                                />
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm">
                              <Mui.TextField label="*Qty" size="small" variant="outlined" className="inputField" {...register("qty")} />
                              {error.filter(error => error.path === 'qty').length > 0 && <p className="error">{error.filter(error => error.path === 'qty')[0].msg}</p>}
                      
                          </Mui.Grid>
                          
                         
                         
                          
                    </Mui.Grid>
                
            </Mui.DialogContent>
          </form>
          
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAllocateVendor;
