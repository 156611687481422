import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from '../lib/MsgBox';
import profileIcon from '../Assets/Images/profile-icon.png'
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerProfile = forwardRef((props,ref) => {
    const [cookie,setCookie,removeCookie] = useCookies(['bas_']);
    const formRef = useRef();
    const chpformRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [loadingchp,setLoadingchp] = React.useState(false)
    const [taxo,setTaxo] = React.useState([])
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, resetField, handleSubmit,reset } = useForm()
  
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'profile/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setCookie('token', result.data.token,{secure: true, sameSite: 'none'});
              setCookie('name', result.data.user.name,{secure: true, sameSite: 'none'});
              setCookie('phone', result.data.user.phone,{secure: true, sameSite: 'none'});
              setCookie('profile',true)
              setValue('name',result.data.user.name)
              setValue('phone',result.data.user.phone)
              //Reset Form
              reset()
              setError([])
              window.location.reload(); 
              loadingOff()
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Update Profile',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
          
    }

    const chPass = (data) => {
      setLoadingchp(true)
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(chpformRef.current));

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'profile/change-pass', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              //Reset Form
              setError([])
              setLoadingchp(false)

              resetField('current_password')
              resetField('new_password')
              resetField('new_password_confirmation')
              setisMsgBox({
                open: true,
                status: 'success',
                title:'Change Password',
                msg: 'Change password successfully',
              })
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              setLoadingchp(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Change Password',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            setLoadingchp(false)
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false
    });

    useImperativeHandle(ref, () => ({
      openForm: (config) => {
        setState({ isForm: true})
        getTaxo(config)
        setValue('username',cookie.username)
        setValue('name',cookie.name)
        setValue('phone',cookie.phone)
      }
   }),[cookie]);

  const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const getTaxo = (config) => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("object", ['level']);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(config.hostApi + 'taxo', requestOptions)
          .then((response) => response.json())
          .then((result) => {

            console.log(result)
            if(result.success){
              loadingOff()
              setTaxo(result.data.level)
              setError([])
              
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              loadingOff()
            }
          })
          .then((response)=>{
            loadingOff()
          })

  }
  React.useEffect(() => {
  },[cookie]);

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Profile User
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <MuiJoy.Card sx={{ mb:3 }}>
              <MuiJoy.Box sx={{ mb: 1 }}>
                  <Mui.Typography level="title-md">Account Package :</Mui.Typography>
              </MuiJoy.Box>
              <Mui.Divider />
              
              <MuiJoy.Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <MuiJoy.Stack direction="column" spacing={1}>
                  <MuiJoy.AspectRatio
                    ratio="1"
                    maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                  >
                    <img
                      src={profileIcon}
                      srcSet={profileIcon}
                      loading="lazy"
                      alt=""
                    />
                  </MuiJoy.AspectRatio>
                </MuiJoy.Stack>
                <MuiJoy.Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <MuiJoy.Stack spacing={1}>
                    <MuiJoy.FormLabel>Username</MuiJoy.FormLabel>
                    <MuiJoy.FormControl>
                        <Mui.OutlinedInput
                          id="outlined-adornment-weight"
                          size="small"
                          inputProps={{ readOnly: true }}
                          endAdornment={<Mui.InputAdornment position="end">@ {cookie.account_id }</Mui.InputAdornment>}
                          {...register('username')} 
                          
                        />
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={1}>
                    <MuiJoy.FormLabel>Name</MuiJoy.FormLabel>
                    <MuiJoy.FormControl>
                        <Mui.TextField 
                            placeholder="Name" 
                            type="text" size="small"
                            {...register('name')}  />
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                  <MuiJoy.Stack direction="row" spacing={2}>
                    <MuiJoy.FormControl>
                      <MuiJoy.FormLabel>Job</MuiJoy.FormLabel>
                      <Mui.Select
                        labelId="level"
                        id="levelr"
                        label="level"
                        size="small"
                        defaultValue={cookie.job_id}
                        {...register("level", { required: true })}
                        >
                          <Mui.MenuItem key={0} value={0}></Mui.MenuItem>
                            {taxo && (taxo.map((level,key) => (
                              level.id == cookie.job_id ? (
                                <Mui.MenuItem key={key+1} value={level.id}>{level.value}</Mui.MenuItem>
                              ):('')
                            
                            )))}
                        </Mui.Select>
                    </MuiJoy.FormControl>
                    <MuiJoy.FormControl sx={{ flexGrow: 1 }}>

                      <MuiJoy.FormLabel>Phone</MuiJoy.FormLabel>
                      <Mui.TextField 
                            placeholder="Phone" 
                            type="text" size="small"
                            {...register('phone')}  />
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                </MuiJoy.Stack>
              </MuiJoy.Stack>
              <MuiJoy.CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                <MuiJoy.CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />}
                    loadingPosition="start"
                    > Update
                  </MuiJoy.Button>
                </MuiJoy.CardActions>
              </MuiJoy.CardOverflow>
            </MuiJoy.Card>
          </form>
          <form ref={chpformRef} onSubmit={handleSubmit(chPass)}>
            <MuiJoy.Card sx={{mb:3}}>
              <MuiJoy.Box sx={{ mb: 1 }}>
                <MuiJoy.Typography level="title-md">Change Password</MuiJoy.Typography>
              </MuiJoy.Box>
              <Mui.Divider />
              <MuiJoy.Stack spacing={2} sx={{ flexGrow: 1, mb:2 }}>
                  <MuiJoy.Stack spacing={1}>
                    <MuiJoy.FormControl>
                      <Mui.TextField 
                          label="Current Password" 
                          type="password" size="small"
                          {...register('current_password')}  />
                      {error.filter(error => error.path === 'current_password').length > 0 && <p className="error">{error.filter(error => error.path === 'current_password')[0].msg}</p>}
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={1}>
                    <MuiJoy.FormControl>
                      <Mui.TextField 
                          label="New Password" 
                          type="password" size="small"
                          {...register('new_password')}  />
                      {error.filter(error => error.path === 'new_password').length > 0 && <p className="error">{error.filter(error => error.path === 'new_password')[0].msg}</p>}
                    </MuiJoy.FormControl>
                  </MuiJoy.Stack>
                  <MuiJoy.Stack spacing={2}>
                    <MuiJoy.FormControl>
                      <Mui.TextField 
                        label="Confirm New Password" 
                        type="password" size="small"
                        {...register('new_password_confirmation')}  />
                        {error.filter(error => error.path === 'new_password_confirmation').length > 0 && <p className="error">{error.filter(error => error.path === 'new_password_confirmation')[0].msg}</p>}
                    </MuiJoy.FormControl>
                    
                  </MuiJoy.Stack>
                </MuiJoy.Stack>

              <MuiJoy.CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                <MuiJoy.CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                  
                  <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loadingchp} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.LockReset />}
                      loadingPosition="start"
                      > Change Password
                    </MuiJoy.Button>
                </MuiJoy.CardActions>
              </MuiJoy.CardOverflow>
            </MuiJoy.Card>
          
          </form>
        </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerProfile;
