import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';

import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';
import MsgBox from '../lib/MsgBox';


const DrawerAddVendor = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };
      
      fetch(props.config.hostApi + 'vendor/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            
            if(result.success){
              refreshVendors()
              loadingOff()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Add Vendor Successfully',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            console.log('disini')
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      vendor:[],
    });

    useImperativeHandle(ref, () => ({
      openForm: () => {
        setState({ 
          isForm: true
         })
      }
   }));

    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    const refreshVendors = () => {
      props.refreshVendorSave()
      setState({
        isForm:false,
        vendor:[],
      })
    }
  React.useEffect(() => {
    

  }); 

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Add New Vendor
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <Mui.Button
                    variant="contained"
                    disabled={state.loading}
                    onClick={()=> setState({isForm:false})}    
                    sx={{ margin: '0px 3px' }}
                  >
                      <MuiIcon.Replay/> Back 
                  </Mui.Button>
                  <MuiLab.LoadingButton
                    type="submit"
                    loading={state.loading}
                    disabled={state.loading}
                    loadingPosition="start"
                    variant="contained"  
                    sx={{ margin: '0px 3px'}}
                    startIcon={<MuiIcon.Save />} 
                    > Save
                  </MuiLab.LoadingButton>
            </Mui.Toolbar>
            <Mui.DialogContent>
              <Mui.Grid container>
                  <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                      <span>* Require Field</span>
                  </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField 
                            label="*Name" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                            {...register("name")} />
                            
                            {error.filter(error => error.path === 'name').length > 0 && <p className="error">{error.filter(error => error.path === 'name')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField 
                          label="Alias" 
                          size="small" 
                          variant="outlined" 
                          className="inputField" 
                          {...register("alias")} />

                          {error.filter(error => error.path === 'alias').length > 0 && <p className="error">{error.filter(error => error.path === 'alias')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="Address " size="small" variant="outlined" className="inputField" {...register("address")} />
                        {error.filter(error => error.path === 'address').length > 0 && <p className="error">{error.filter(error => error.path === 'address')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="*Phone " size="small" variant="outlined" className="inputField" {...register("phone")} />
                        {error.filter(error => error.path === 'phone').length > 0 && <p className="error">{error.filter(error => error.path === 'phone')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="*Email " size="small" variant="outlined" className="inputField" {...register("email")} />
                        {error.filter(error => error.path === 'email').length > 0 && <p className="error">{error.filter(error => error.path === 'email')[0].msg}</p>}
                
                    </Mui.Grid>
                    
              </Mui.Grid>
          
            </Mui.DialogContent>
          </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddVendor;
