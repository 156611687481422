import React,  { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import * as MuiJoy from '@mui/joy';


import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import { Fullscreen, Slideshow, Captions, Thumbnails,Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

import { MuiFileInput } from 'mui-file-input';
import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';


const DrawerQCRepair = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [form,setForm] = React.useState(0)
    const [qc,setqc] = React.useState([])
    const [open, setOpen] = React.useState([]);
    const [showToggle, setShowToggle] = React.useState(false);
    const [descriptionMaxLines, setDescriptionMaxLines] = React.useState(3);
    const [descriptionTextAlign, setDescriptionTextAlign] = React.useState("center");
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, getValues, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization",cookies.token);

        const formdata = new FormData();
        formdata.append("vendor_allocation_id", parseInt(state.vendorAllocationId))
        formdata.append("pass", getValues("pass"))

        //setup note
        var notes = '[]';
        var noteCount = getValues("note")
        if(noteCount != undefined){
            notes = '['
            for (var i = 0; i < getValues("note").length ; i++ ) {
              notes +=  `{\"note\":\"${getValues("note")[i]}\"}`
              if(i < (getValues("note").length-1) ){ notes += `,` }
            }
            notes += ']'
        }
        formdata.append("detail_qc", notes)

        //Setup Photo
        if(filePhoto != null){
            for (var i = 0; i < filePhoto.length ; i++ ) {
              formdata.append("files[]", filePhoto[i], filePhoto[i].name)
            }
          }
        /*for (var pair of formdata.entries()) {
              console.log(pair); 
          }*/
         
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };


        fetch(props.config.hostApi + 'repair/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setState({ 
                isForm: false
              })
              loadingOff()
              reset()
              setfilePhoto(null)
              setfileArray([])
              props.refreshQC(0,state.vendorAllocationId, state.productDesc, state.productId)
              setisMsgBox({
                open: true,
                status: 'success',
                title:'Add QC Repair',
                msg: 'QC Submiting Successfully',
              })
              setForm(0)
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Add QC Repair',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
            

      
    }
    

    const [state, setState] = React.useState({
      isForm:false,
      vendorAllocationId: '',
      productDesc:'',
      productId:''
    });

    useImperativeHandle(ref, () => ({
      openForm: (id,productDesc,productId) => {
        
        //Filter QC
        let qc = props.qc.filter(_qc => _qc.id === id)
        if(qc.length>0){
          if(qc[0].qc.length>0){
            qc = qc[0].qc.filter(_qc => _qc.qc_type === 2)
          }else{
            qc = []
          }
        }else{
          qc = []
        }

        setState({ 
          isForm: true, 
          vendorAllocationId: id,
          productDesc: productDesc,
          productId: productId,
          qc: qc
        })
        setForm(0)
        const _open = qc.map((_qc, i) => {
          return false
        });
        setOpen(_open);
      }
   }));

   const showQC =() =>{
        //Filter QC
        let qc = props.qc.filter(_qc => _qc.id === state.vendorAllocationId)
        if(qc.length>0){
          console.log(qc[0].qc)
          if(qc[0].qc.length>0){
            qc = qc[0].qc.filter(_qc => _qc.qc_type === 2)
          }else{
            qc = []
          }
        }else{
          qc = []
        }

        setState({ 
          isForm: true, 
          vendorAllocationId: state.vendorAllocationId,
          productDesc: state.productDesc,
          productId: state.productId,
          qc: qc
        })
        setForm(0)
        const _open = qc.map((_qc, i) => {
          return false
        });
        setOpen(_open);
   }


    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    const [fileObj,setfileObj] = useState([]);
    const [fileArray,setfileArray] = useState([]);
    const [filePhoto,setfilePhoto] = useState(null);

    const uploadMultipleFiles1 = (e)=>{
      setfileObj([])
      fileObj.push(e)
      for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]))
      }
    }

    const deletePhoto = (index) => {
      setfilePhoto(null);
      setfileArray([])
    }

    const openGallery = (index,action) => {
      const _open = open.map((c, i) => {
        if (i === index) { return action } else { return false }
      });
      setOpen(_open);
    }

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Product Repaired : {state.productDesc}
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  <Mui.Button
                    variant="contained"
                    disabled={state.loading}
                    onClick={()=> setState({
                      isForm: false, 
                        vendorAllocationId: '',
                        qc: [] 
                    })}    
                    sx={{ margin: '0px 3px' }}
                  >
                      <MuiIcon.Replay/> Back 
                  </Mui.Button>
                  {form === 0 ?(
                        <Mui.Button
                          variant="contained"
                          disabled={state.loading}
                          onClick={()=> {
                            setForm(1)
                          }}
                          sx={{ margin: '0px 3px' }}
                        >
                            <MuiIcon.EditNote/> Repair Form 
                        </Mui.Button>
                  ):(
                    <>
                        <MuiLab.LoadingButton
                        type="submit"
                        loading={state.loading}
                        disabled={state.loading}
                        loadingPosition="start"
                        variant="contained"  
                        sx={{ margin: '0px 3px'}}
                        startIcon={<MuiIcon.Save />} 
                        > Submit
                      </MuiLab.LoadingButton>
                    <Mui.Button
                        variant="contained"
                        disabled={state.loading}
                        onClick={()=> {
                            showQC()
                            setForm(0)
                          }
                        }    
                        sx={{ margin: '0px 3px' }}
                      >
                          <MuiIcon.List/> View List 
                      </Mui.Button>
                    </>
                  )}
            </Mui.Toolbar>
            <Mui.DialogContent>
                {form===0 ? (
                  <Mui.Grid container>
                      <Mui.Grid item xs={12} className="innerForm" align="right">
                          <span>QC Repair Activity</span>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                            <Mui.Table size="small" aria-label="purchases" sx={{marginTop:3, marginBottom:7}}>
                                <Mui.TableHead>
                                  <Mui.TableRow>
                                    <Mui.TableCell>Date</Mui.TableCell>
                                    <Mui.TableCell>PIC</Mui.TableCell>
                                    <Mui.TableCell align="right">Repaired</Mui.TableCell>
                                    <Mui.TableCell align="right">&nbsp;</Mui.TableCell>
                                  </Mui.TableRow>
                                </Mui.TableHead>
                                <Mui.TableBody>
                                  {state.qc ? ( state.qc.map((_qc,key) => (
                                    <Mui.TableRow key={key}>
                                      <Mui.TableCell component="th" scope="row">{new Date(_qc.createdAt).toLocaleString('id-ID',{ day: 'numeric', month: 'numeric', year: 'numeric'})}</Mui.TableCell>
                                      <Mui.TableCell>{_qc.user_name}</Mui.TableCell>
                                      <Mui.TableCell align="right">{_qc.pass}</Mui.TableCell>
                                      <Mui.TableCell align="center">
                                      <MuiJoy.Button
                                                type="button"
                                                variant="solid" 
                                                style={{ background:'none',color :'#222222', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                                onClick={ ()=> {
                                                  openGallery(key, true)
                                                }}
                                                > <MuiIcon.Photo />
                                              </MuiJoy.Button>

                                                <Lightbox
                                                  open={open[key]}
                                                  close={() => openGallery(key, false)}
                                                  slides={_qc.qc_photo}
                                                  plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
                                                  captions={{ showToggle, descriptionTextAlign, descriptionMaxLines }}
                                                />
                                      </Mui.TableCell>
                                    </Mui.TableRow>
                                  ))):('')}
                                </Mui.TableBody> 
                              </Mui.Table>
                      </Mui.Grid>
                  </Mui.Grid>
                ) : (
                  <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                            <span>* Require Field</span>
                        </Mui.Grid>
                        <Mui.Grid item xs={8} className="innerForm">
                              <Mui.TextField label="*Repaired" size="small" variant="outlined" className="inputField" {...register("pass")} />
                              {error.filter(error => error.path === 'pass').length > 0 && <p className="error">{error.filter(error => error.path === 'pass')[0].msg}</p>}
                      
                          </Mui.Grid>
                         
                          
                          <Mui.Grid item xs={4} className="innerForm">
                              <MuiFileInput
                                  placeholder="UploadPhoto" 
                                  value={filePhoto}
                                  sx={{border:'1px solid #FF9B00', borderRadius:'5px'}}
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    startAdornment: <MuiIcon.AttachFile />
                                  }}
                                  clearIconButtonProps={{
                                    children: <MuiIcon.Close fontSize="12px" />,
                                    onClick:()=>{
                                      deletePhoto()
                                    }
                                  }}
                                  onChange={(newfile) => {
                                    setfilePhoto(newfile);
                                    uploadMultipleFiles1(newfile)
                                  }} 
                                  multiple
                              />
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                                  

                                  <Mui.ImageList sx={{ width: '100%', height: 450 }}>
                                    
                                  {fileArray.map((url,key) => (
                                      <Mui.ImageListItem key={key}>
                                        <img
                                            src={url}
                                          />
                                          <Mui.TextField label="Note" {...register(`note.${key}`)} sx={{margin:'5px 0 10px 0'}} size="small" variant="outlined" className="inputField" />
                                      </Mui.ImageListItem>
                                    ))}
                                  </Mui.ImageList>
                          </Mui.Grid>
                    </Mui.Grid>
                ) }
                    
                 
            </Mui.DialogContent>
          </form>
          
      </Mui.Drawer>  
    </>
  );
});

export default DrawerQCRepair;
