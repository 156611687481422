import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import CurrencyInput from 'react-currency-input-field';
import MsgBox from "../lib/MsgBox";
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerAddUser = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [price,setPrice] = React.useState(0)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    var obj;
    const onSubmit = (data) => {
      loadingOn()
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };
      
      fetch(props.config.hostApi + 'user/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            
            obj = result
            if(result.success){
              refreshUser()
              loadingOff()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Add User Successfully',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            console.log('disini')
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      taxo:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (taxo) => {
        setState({ isForm: true, taxo: taxo})
      }
    }));

   const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const refreshUser = () => {
    props.refreshUserSave()
    setState({isForm:false,taxo:[]})
  }


  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Add New User
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false,id:'',user:[],taxo:[]})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
              <MuiJoy.Button
                type="submit"
                variant="solid" 
                loading={loading} 
                sx={{ margin: '0px 3px'}}
                startDecorator={<MuiIcon.Save />}
                loadingPosition="start"
                > Save
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
                <Mui.Grid container>
                    <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                        <span>* Require Field</span>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm" >
                        <Mui.FormControl variant="outlined" size="small" className="inputField">
                          <Mui.InputLabel htmlFor="outlined-adornment-username"
                            
                          >*Username</Mui.InputLabel>
                          <Mui.OutlinedInput
                            id="outlined-adornment-username"
                            size="small"
                            variant="outlined" 
                            className="inputField" 
                            endAdornment={
                              <Mui.InputAdornment position="end" >
                                @ { cookies.account_id }
                              </Mui.InputAdornment>
                            }
                            {...register('username')} 
                            label="username"
                          />
                          {error.filter(error => error.path === 'username').length > 0 && <p className="error">{error.filter(error => error.path === 'username')[0].msg}</p>}
                          </Mui.FormControl>
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm" >
                          <Mui.TextField 
                            label="*Name" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                              {...register("name")} 
                          />
                          {error.filter(error => error.path === 'name').length > 0 && <p className="error">{error.filter(error => error.path === 'name')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm" >
                          <Mui.TextField 
                            label="*Phone" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                              {...register("phone")} 
                          />
                          {error.filter(error => error.path === 'phone').length > 0 && <p className="error">{error.filter(error => error.path === 'phone')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">

                          <Mui.FormControl size="small" className="inputField">
                            <Mui.TextField
                                select
                                size="small"
                                label="*Job"
                                {...register("job_id")} 
                            >
                                {state.taxo.map((level,key) => (
                                <Mui.MenuItem key={key} value={level.id}>{level.value}</Mui.MenuItem>
                                ))}
                            </Mui.TextField>
                          </Mui.FormControl>
                          {error.filter(error => error.path === 'job_id').length > 0 && <p className="error">{error.filter(error => error.path === 'job_id')[0].msg}</p>}

                      </Mui.Grid>
                      <br />
                      <Mui.Typography variant="h6" style={{marginLeft:'20px', marginTop:'40px'}}>Setup Password</Mui.Typography>
                        <Mui.Divider variant="middle" style={{marginBottom:'20px', paddingBottom:'10px'}} />

                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="Password " size="small" variant="outlined" className="inputField" 
                              type="password"
                              {...register("password")} />
                          {error.filter(error => error.path === 'password').length > 0 && <p className="error">{error.filter(error => error.path === 'password')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="Confirm Password" size="small" variant="outlined" className="inputField" 
                              type="password"
                              {...register("password_confirmation")} />
                          {error.filter(error => error.path === 'password_confirmation').length > 0 && <p className="error">{error.filter(error => error.path === 'password_confirmation')[0].msg}</p>}

                      </Mui.Grid>
                </Mui.Grid>
        </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddUser;
