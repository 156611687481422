import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import exportFromJSON from 'export-from-json';
import Header from '../lib/Header';
import axios from 'axios';
import GetStatus from '../lib/GetStatus';
import MsgBox from '../lib/MsgBox';
import { useForm, SubmitHandler } from "react-hook-form"

import DrawerAddProject from '../drawer/DrawerAddProject';

const Dashboard = (props) => {
  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [exportXls,setExportXls] = useState(false)
  const [projects,setProjects] = useState([]);
  const [isMsgBox, setisMsgBox] = React.useState({})
  const addRef = useRef();
  const formRef = useRef();
  const [storage,setStorage] = useState();
  const [page,setPage] = React.useState()
  var obj;

  
  const changePage = (event,value) => {
    setPage(value);
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    perPage: 10,
    project_type_id: '',
    status: ''
  })

  const { register, setValue, handleSubmit,reset } = useForm()
  const onSubmit = (data) => {
    setPage(1)
    setFilter({
      search: data.search,
      perPage: data.perPage,
      project_type_id: '',
      status: ''

    })
}
  const getData = () => {
    setContentLoading(true)
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(props.config.hostApi + '/project', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        obj = result
        if(obj.success){
          setProjects(result.data)
            setContentLoading(false)
        }else{
          //setError(obj.errors)
          setContentLoading(false)
          setisMsgBox({
            open: true,
            status: 'error',
            title:'Project Monitoring',
            msg: obj.message,
          })
        }
      })
      .then(()=>{
        setContentLoading(false)
      })
      
      
}

const getProjects = () => {
  setContentLoading(true)
  
  const myHeaders = new Headers();
  myHeaders.append("Authorization",cookies.token);

  const urlencoded = new URLSearchParams();
  urlencoded.append("search", filter.search);
  urlencoded.append("perPage", filter.perPage);
  urlencoded.append("project_type_id", filter.project_type_id);
  urlencoded.append("status", filter.status);
  urlencoded.append("page", page);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: urlencoded
  };

  fetch(props.config.hostApi + '/project', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      obj = result
      if(obj.success){
//        console.log(result)
        setStorage(result.storage)
        setPagination(result.pagination)
        setProjects(result.data)
        setContentLoading(false)
      }else{
        //setError(obj.errors)
        setContentLoading(false)
        setisMsgBox({
          open: true,
          status: 'error',
          title:'Project Monitoring',
          msg: obj.message,
        })
      }
    })
    .then(()=>{
      setContentLoading(false)
    })
    
}

const exportXlsReport = () => {
  setExportXls(true)
  const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(new FormData());
    urlencoded.append("perPage", 10);
    urlencoded.append("search", filter.search);
    urlencoded.append("project_type_id", filter.project_type_id);
    urlencoded.append("status", filter.status);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };


    fetch(props.config.hostApi + 'project/export', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            const data = result.data
            const fileName = 'SIMPRO - Project Monitoring';
            const exportType = 'xls';
            exportFromJSON({ data, fileName, exportType });
            console.log('success')
          }else{
            console.log('not success') 
          }
        })
        .then((error)=>{
          setExportXls(false)
          console.log(error)
        })
}
const afterSave=()=>{
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Add New Project',
    msg: 'Saving new Project successfully.'
  })
}
React.useEffect(() => {
  getProjects()
}, [filter,page]); 

  return (
    <div>

      <MsgBox config={isMsgBox} />
      <DrawerAddProject ref={addRef} config={props.config} refreshProjectSave={afterSave} />

      <Header config={props.config} />
      <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
        <Mui.Grid item md={12}>
          <Mui.Toolbar disableGutters>
              <Mui.Breadcrumbs aria-label="breadcrumb">
                  <Mui.Link
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                  >
                    <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                  </Mui.Link>
                  
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    Project Monitoring
                  </Mui.Typography>
              </Mui.Breadcrumbs>

              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    addRef.current.openForm();
                  }}
                  > <MuiIcon.BookmarkAdd /> &nbsp; Add New Project
              </MuiJoy.Button>
              <MuiJoy.Button
                type="button"
                variant="solid" 
                style={{ background:'#0F7C41', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                onClick={()=>{
                  exportXlsReport()
                }}
                > &raquo; Export to Ms Excel
                  {exportXls ? <MuiJoy.LinearProgress
                    variant="solid"
                    color="success"
                    value={40}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      borderRadius: '25px',
                      margin: '0px 10px'
                    }}
                  /> : '' }
              </MuiJoy.Button>
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#555555', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    if(filterEnable)
                      setFilterEnable(false)
                    else  
                      setFilterEnable(true)
                  }}
                  >  <MuiIcon.ManageSearch /> &nbsp; Filter
                   
                </MuiJoy.Button>
                &nbsp; <span style={{fontSize:'12px', textAlign:'right'}}>Storage Used<br /> {storage}</span>
          </Mui.Toolbar>
        </Mui.Grid>
      </Mui.Grid>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="Project Type"
                          {...register("projectType")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="Status"
                          {...register("status")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={contentLoading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
      {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
          ) : (
              <>
              
              <Mui.Grid item xs={12}>
                <Mui.Box style={{padding:'20px'}}>
                      <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>PID</Mui.TableCell>
                                <Mui.TableCell>Date</Mui.TableCell>
                                <Mui.TableCell>PO Number</Mui.TableCell>
                                <Mui.TableCell>Supervisor</Mui.TableCell>
                                <Mui.TableCell align='center'>Project Type</Mui.TableCell>
                                <Mui.TableCell align='center'>Product Item</Mui.TableCell>
                                <Mui.TableCell align='center'>Product Total</Mui.TableCell>
                                <Mui.TableCell align='center'>OnProgress / OnRepair / Finished / Approved / Paid</Mui.TableCell>
                                <Mui.TableCell align='center'>Status</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { projects.map((project,key) => (
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                <Mui.TableCell>{project.id}</Mui.TableCell>
                                <Mui.TableCell>{new Date(project.project_date).toLocaleString('id-ID',{ day: 'numeric', month: 'numeric', year: 'numeric'})}</Mui.TableCell>
                                <Mui.TableCell>{project.po_number}</Mui.TableCell>
                                <Mui.TableCell>{project.user_name}</Mui.TableCell>
                                <Mui.TableCell align='center'>{project.project_type_name}</Mui.TableCell>
                                <Mui.TableCell align='center'>{project.product_item}</Mui.TableCell>
                                <Mui.TableCell align='center'>{project.product_total}</Mui.TableCell>
                                <Mui.TableCell align='center'>
                                {project.total_onprogress < 1 ? (
                                          <MuiJoy.Button
                                            type="button"
                                            variant="solid" 
                                            style={{ background:'#EBEBEB', color:'#333333', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                            > 0</MuiJoy.Button>
                                    ):( 
                                      <MuiJoy.Button
                                        type="button"
                                        variant="solid" 
                                        style={{ background:'#FAE2C0', color:'#000000', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                        > {project.total_onprogress} 
                                    </MuiJoy.Button>)}/
                                    {project.total_onrepair < 1 ? (
                                          <MuiJoy.Button
                                            type="button"
                                            variant="solid" 
                                            style={{ background:'#EBEBEB', color:'#333333', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                            > 0</MuiJoy.Button>
                                    ):( 
                                      <MuiJoy.Button
                                          type="button"
                                          variant="solid" 
                                          style={{ background:'#F37202', color:'#ffffff', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                          > {project.total_onrepair} 
                                      </MuiJoy.Button>)}/
                                    {project.total_finished < 1 ? (
                                          <MuiJoy.Button
                                            type="button"
                                            variant="solid" 
                                            style={{ background:'#EBEBEB', color:'#333333', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                            > 0</MuiJoy.Button>
                                    ):( 
                                      <MuiJoy.Button
                                          type="button"
                                          variant="solid" 
                                          style={{ background:'#73B3B2', color:'#000000', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                          > {project.total_finished} 
                                      </MuiJoy.Button>)}/
                                    {project.total_approved < 1 ? (
                                          <MuiJoy.Button
                                            type="button"
                                            variant="solid" 
                                            style={{ background:'#EBEBEB', color:'#333333', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                            > 0</MuiJoy.Button>
                                    ):( 
                                      <MuiJoy.Button
                                          type="button"
                                          variant="solid" 
                                          style={{ background:'#257972', color:'#ffffff', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                          > {project.total_approved} 
                                      </MuiJoy.Button>)}/
                                    {project.total_paid < 1 ? (
                                          <MuiJoy.Button
                                            type="button"
                                            variant="solid" 
                                            style={{ background:'#EBEBEB', color:'#333333', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                            > 0</MuiJoy.Button>
                                    ):( 
                                          <MuiJoy.Button
                                              type="button"
                                              variant="solid" 
                                              style={{ background:'#128C39', color:'#ffffff', margin:'0px 3px', padding: '0 15px', borderRadius:'25px', fontSize: '12px'}} 
                                              > {project.total_paid} 
                                          </MuiJoy.Button>
                                    )}
                                </Mui.TableCell>
                                <Mui.TableCell align="center"><GetStatus status={project.status} statusInfo={project.status_info} /></Mui.TableCell>
                                <Mui.TableCell>
                                    <MuiJoy.Dropdown>
                                        <MuiJoy.MenuButton
                                          slots={{ root: MuiJoy.IconButton }}
                                          slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                        >
                                          <MuiIcon.MoreVert />
                                        </MuiJoy.MenuButton>
                                        
                                        <MuiJoy.Menu placement="bottom-end">
                                          <Mui.Link href={"/project-draft/" + project.id} style={{textDecoration:'none', color:'#000000'}}>
                                              <MuiJoy.MenuItem>
                                                <MuiJoy.ListItemDecorator>
                                                  <MuiIcon.Edit />
                                                </MuiJoy.ListItemDecorator>{' '}
                                                View Project
                                              </MuiJoy.MenuItem>
                                            </Mui.Link>
                                          
                                          
                                        </MuiJoy.Menu>
                                      </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                        )) }
                        </Mui.TableBody>
                    </Mui.Table>
                      
                  </Mui.Box>
              </Mui.Grid>
              <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
          )}
    </Mui.Grid>
    </div>
  );
}

export default Dashboard;



